import React from 'react'
import { Link } from 'gatsby'

import Layout from '../../components/layout'
import Header from '../../components/header'


const Portfolio1 = ({data}) => (
  <Layout>
      <main className="page--portfolio portfolio--designsystem">

        <section className="slab slab--hero">
        <Header siteTitle="Rob DiMarzo" /> 

          <div className="slab__inner">
            <div className="pageblock--title">
              <h1>
                {data.site.siteMetadata.designsystemTitle}
              </h1>
              <span>
                {data.site.siteMetadata.designsystemDesc}
              </span>
              <div className="hero__jumplinks">
                <span>Jump to:</span>
                <a href="#intro">Introduction</a>
                <a href="#goals-challenges">Goals & Challenges</a>
                <a href="#my role">My Role</a>
              </div>

            </div>
          </div>  
          {/* <Gallery/> */}
        </section>

        <section className="slab slab--intro" id="intro" >
          <div className="slab__inner">
            <div className="pageblock--title">
              <h2>Coming soon</h2>
              <div className="title__line"></div>
            </div>
            <div className="pageblock">
              <div className="pageblock__content">
                <p>This page is a work in progress. </p>            
                

              </div>
            </div>
          </div>
        </section>
        

    </main>
  </Layout>
)

export const query = graphql`
  query DesignSystemQuery {
    site {
      siteMetadata {
        designsystemTitle
        designsystemDesc
      }
    }
  }
`
export default Portfolio1
